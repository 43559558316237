/* App.css */

/* Definice CSS proměnných */
:root {
  --primary-color: #4CAF50;                /* Zelená barva a zelené pozadí tlačítek */
  --primary-dark: #24712a;                 /* Tmavší zelená barva při najetí a vybrané tlačítko */
  --secondary-color: #007bff;
  --secondary-dark: #0056b3;
  --background-color: #f0f8f0;             /* Světle šedé pozadí */
  --green-background-color: #e0f2f1;       /* Světle zelené pozadí pro seznam tlačítek */
  --green-background-color-light: #e8f5e9; /* Světlejší zelené pozadí */
  --border-color: #ccc;
  --text-color-light: #fff;
}

/* Obecný styl pro celé tělo aplikace */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
}

/* Hlavní kontejner pro centrální obsah */
.App {
  height: 100vh; /* Výška obrazovky */
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontální zarovnání */
  text-align: center;
}

/* Styl pro nadpis */
h1 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

/* Kontejner pro dvě části vedle sebe */
.container {
  display: flex;
  align-items: stretch; /* Roztažení do výšky */
  gap: 20px; /* Mezera mezi částmi */
  padding: 0 20px;
  height: 80vh; /* Výška kontejneru pro dvě části */
  width: 100%; /* Šířka kontejneru */
}

/* Styl pro seznam hlasování */
.voting-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: auto; /* Přidání scrollování, pokud je potřeba */
  background-color: var(--green-background-color);
  padding: 10px;
  border-radius: 5px; /* Zaoblené rohy */
}

/* Styl pro detail hlasování */
.voting-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2;
  background-color: var(--green-background-color-light);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Přidání scrollování, pokud je potřeba */
}

/* Styl pro tlačítka */
button {
  background-color: var(--primary-color);
  border: none;
  color: var(--text-color-light);
  padding: 10px 20px;
  margin: 10px 0; /* Vzdálenost mezi tlačítky */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px; /* Zaoblené rohy */
  cursor: pointer;
  min-width: 50%;
}

/* Styl pro vybrané tlačítko */
button.selected {
  background-color: var(--primary-dark);
}

/* Styl pro tlačítka při najetí myší */
button:hover {
  background-color: var(--primary-dark);
}

.refresh-button {
  background-color: var(--secondary-color);
}

.refresh-button:hover {
  background-color: var(--secondary-dark);
}

.grow-spacer {
  flex-grow: 1;
}
